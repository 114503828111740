@media (min-width: 1280px) {
  // General settings
  p, span, li {
    font-size: 1.2rem;
  }

  .page-banner {
    h1 {
      padding-top: 6%;
    }
  }

  .page-divider {
    width: 50%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .divider {
    margin-bottom: 2.5%;
  }

  // Navigation
  .banner {
    padding: 0;

    span {
      margin-left: 1%;
    }
  }

  .logo {
    width: 20%;
    margin-top: 1%;
    margin-bottom: 0.5%;
  }

  .menu {
    margin-top: 9.2%;
  }

  // Footer
  .footer-info {
    text-align: left;
    display: flex;
    padding: 10%;
    padding-top: 2%;
    padding-bottom: 3%;

    h2 {
      font-size: 1.6rem;
    }
  }

  .footer-heading {
    font-size: 1.5rem;
  }

  .col-one {
    width: 50%;
  }

  .col-two {
    text-align: left;
    width: 75%;
  }

  .footer-link {
    text-align: left;
  }

  .social-icons {
    justify-content: left;
    margin-top: 4%;

    a {
      text-align: center;
      width: 4%;
      margin: 0;
      margin-right: 3%;
      padding: 2%;
    }
  }

  .tax-badge {
    height: 25%;
    width: 17%;
    margin: 0;
  }

  .footer-badges {
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .xero-badge {
    height: 20%;
    width: 24%;
  }

  .quickbooks-badge {
    height: 20%;
    width: 12%;
  }

  .bark-badges {
    justify-content: left;
  }

  .bark-badge {
    margin: 0;
    margin-right: 5%;
  }

  // Privacy Policy
  .privacy-policy {
    overflow: hidden;

    h1, h2, p {
      width: 70%;
    }

    h1 {
      font-size: 1.8rem;
      margin-top: 2%;
      margin-bottom: 2%;
    }

    p {
      margin-top: 1%;
      margin-bottom: 1%;

      &:last-child {
        margin-bottom: 2%;
      }
    }

    li {
      margin: 1%;
      margin-left: 18%;
    }

    .policy-heading {
      font-size: 1.4rem;
      margin-top: 2%;
      margin-bottom: 0;
    }
  }

  // Automatic Pop-Up
  .pop-up {
    .pop-up-content {
      margin: auto;

      h3 {
        font-size: 2rem;
        margin-top: 2%;
        margin-bottom: 2%;
      }

      p {
        font-size: 1.5rem;
      }

      input {
        margin-top: 3%;
      }

      .submit-email {
        margin-bottom: 3%;
      }
    }
  }

  .pop-up-card {
    height: 50%;
    width: 50%;
    margin-top: 10%;
  }

  // Home
  .home {
    p {
      margin-top: 1%;
    }
  }

  .home-img {
    height: 30rem;

    h1 {
      padding-top: 10%;
    }
  }

  .info-row {
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      margin-top: 2%;
    }

    &:last-child {
      margin-bottom: 2%;
    }
  }

  .info-boxes {
    width: 90%;
    margin: auto;
  }

  .info-box {
    font-size: 1rem;
    height: 28rem;
    width: 25%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 2%;
    padding: 1%;
    line-height: 2rem;

    p {
      margin-top: 2%;
    }
  }

  .parallax-container {
    min-height: 620px;
  }

  .testimonials-heading {
    p {
      padding-top: 5%;
    }
  }

  .heading-divider {
    margin-bottom: 0;
  }

  .name {
    padding-top: 2%;
  }

  .why-info-section {
    h2 {
      margin-top: 3%;
    }

    p:last-child {
      margin-bottom: 3%;
    }

    .blue-checkmark {
      margin-bottom: -0.5%;
    }
  }

  .financial-services-section {
    h2 {
      margin-top: 5%;
      margin-bottom: 2%;
    }

    p {
      width: 50%;
    }

    .contact-link {
      p {
        margin-bottom: 3%;
      }
    }
  }

  .info-squares {
    display: flex;
    justify-content: center;
  }

  .info-square {
    height: 280px;
    width: 18%;
    margin: 0;
    margin-top: 3%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 0;

    &:last-child {
      margin-bottom: 2%;
    }

    p {
      font-size: 1.5rem;
      width: 80%;
    }

    .info-square-icon {
      height: 18%;
      width: 18%;
      margin-top: 22%;
    }
  }

  // About Us
  .about-us {
    h2 {
      margin-top: 3%;
    }

    p {
      width: 70%;
      margin-top: 0.5%;
    }

    p:last-child {
      margin-bottom: 2%;
    }

    li {
      margin-top: 1%;
      margin-left: 16%;
    }

    li:last-child {
      margin-bottom: 1%;
    }
  }

  // Blog
  .blog-post {
    width: 70%;
    margin-bottom: 5%;
  }

  .blog-img {
    height: 500px;
    width: 100%;
    margin-top: 5%;
    object-fit: cover;
  }

  .blog-content {
    width: 70%;
    margin-top: 4%;
    margin-bottom: 4%;

    li {
      margin-top: 1%;
      margin-bottom: 1%;
    }
  }

  .blog-post-bg-img {
    h1 {
      padding-top: 10%;
    }

    .date {
      margin-top: 4%;
    }
  }

  // Services
  .services {
    p {
      width: 70%;
      margin-top: 2%;
      margin-bottom: 2%;
    }

    .more-info-heading {
      margin-bottom: 1%;
    }
  }

  .info-links {
    width: 90%;
  }

  .info-container {
    display: flex;
  }

  .info-img {
    width: 50%;

    &:first-child {
      margin-bottom: 3%;
    }
  }

  .middle-img {
    display: block;
    margin-bottom: 3%;
  }

  .info-area {
    width: 70%;

    h2 {
      width: 70%;
    }
  }

  .blue-checkmark {
    margin-bottom: -1%;
  }

  .disclaimer {
    font-size: 1.1rem;
  }

  // Free Resources
  .free-resources {
    h1 {
      font-size: 1.8rem;
      margin-top: 2%;
      margin-bottom: 2%;
    }

    li {
      margin-top: 0;
      margin-bottom: 0;
    }

    .resources-heading {
      margin-bottom: 2%;
    }

    .links {
      font-size: 1.2rem;
    }

    .disclaimer {
      margin-top: 0;
      margin-bottom: 2%;
    }
  }

  // Contact Us
  .contact-us {
    p {
      margin-top: 1%;
    }
  }

  .contact-us-banner {
    h1 {
      padding-top: 4%;
    }

    p {
      margin-top: 2%;
    }
  }

  .location-img {
    width: 40%;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .map {
    margin-top: 2%;
    margin-bottom: 3%;
  }
}